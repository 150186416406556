import { FileLinksContext } from "context/fileLinksContext";
import { useContext } from "react";

type DownloadFilesProps = {
    fileIds: string[],
    onError?: (error: string) => void
}

export default function useDownloadFile() {

    const fileLinksContext = useContext(FileLinksContext);

    return { downloadFiles };

    function downloadFiles({ fileIds, onError }: DownloadFilesProps) {

        fileLinksContext.getFileLinks({
            fileIds: fileIds,
            onSuccess: (filesLinks) => filesLinks.forEach(
                fileLinks => {
                    const iframe = document.createElement('iframe');
                    iframe.src = fileLinks.link;
                    iframe.style.display = 'none';
                    document.body.appendChild(iframe);
                    setTimeout(() => {
                        document.body.removeChild(iframe);
                    }, 10000);
                }
            ),
            onError
        })
    }
}
