import { Box, Stack, Typography } from '@mui/material';
import { MetadataTable } from 'components/MetadataTable';
import { FileNameField } from 'components/FileNameField';
import { ChangeEvent } from 'react';
import { Metadata } from 'types';
import { ButtonBar } from './ButtonBar';

export type FileEditData = {
    name?: string
    metadatas?: Metadata[];
};

export type FileEditFormProps = {
    data: FileEditData;
    onChange?: (data: FileEditData) => void;
    isSaving?: boolean;
    onSave?: () => void;
    onCancel?: () => void;
};

export function FileEditForm({ data = {}, onChange, isSaving, onSave, onCancel }: FileEditFormProps) {

    const noData = data?.metadatas?.length <= 0;

    return (
        <Box sx={(theme) => ({ height: `calc(100vh - ${theme.spacing(24)})` })}>
        <Stack gap={1} height="100%" overflow="auto">
            <Box >
                <FileNameField
                    disabled={isSaving}
                    label="Name"
                    variant="outlined"
                    value={data?.name}
                    fullWidth                    
                    inputProps={{ maxLength: 200 }}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) => onChange({ ...data, name: event.target.value })} />
            </Box>
            <Box flexGrow={1} overflow="auto" >                
                <Typography variant="h5">Metadata</Typography>
                <MetadataTable metadatas={data?.metadatas} disabled={isSaving} onChange={(metadatas) => onChange({ ...data, metadatas })} displaySystemMetadata/>
            </Box>
            <Box >
            <ButtonBar
                save={onSave && {
                    onClick: onSave,
                    loading: isSaving,
                    disabled: noData,
                }}
                cancel={onCancel && {
                    onClick: onCancel,
                    disabled: isSaving
                }} />
            </Box>
        </Stack>
        </Box>
    );
}
