import { Stack, Typography, Box } from "@mui/material";
import { last } from "lodash";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

export function FileIcon({ fileName, fontSize = 260, fill = false }: { fileName: string; fontSize?: number; fill?: boolean; }) {
    const size = fill ? "100%" : `${fontSize}`;
    return (
        <svg
            width={size}
            height={size}
            className="fileIcon"
            viewBox="0 0 260 260">
            <foreignObject width="100%" height="100%" xmlns="http://www.w3.org/1999/xhtml">
                <Stack alignItems="center">
                    <InsertDriveFileOutlinedIcon sx={{ fontSize: 260 }} />
                    <Box width="50%" marginTop="-110px" overflow="hidden" textAlign="center">
                        <Typography variant="h4" noWrap>{getFileExtension(fileName)}</Typography>
                    </Box>
                </Stack>
            </foreignObject>
        </svg>
    );
}

export function getFileExtension(fileName: string) {
    const extFrags = fileName.split(".");
    if (extFrags.length === 1)
        return "";
    return `.${last(extFrags)}`;
}