import { useQuery } from "@tanstack/react-query";
import { TenantContext } from "context";
import { useIdentity } from "hooks";
import { useContext } from "react";
import { TenantConfigDto } from "types";

export function useConfigApi() {

    const { getIdentity } = useIdentity();
    const { tenant } = useContext(TenantContext);

    return useQuery({
        queryKey: [getIdentity],
        queryFn: async () => {
            const identity = await getIdentity();
            const res = await fetch(`${(window as any).REACT_APP_API_ENDPOINT}/v1/${tenant.path}/config`, {
                headers: { Authorization: `Bearer ${identity.tokenV2}` }
            });
            if (!res.ok)
                throw new Error(`${res.statusText}`);
            const config = await res.json();
            return config as TenantConfigDto;
        }
    });
}