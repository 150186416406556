import { FileLinks } from "types";
import { ContentResponse } from "./useContentHeaders";
import contentDisposition from "content-disposition";


export function useFilestoreLink(fileId: string, url: string, contentResponse: ContentResponse): FileLinks {
    return {
        id: fileId,
        link: url,
        type: getFileType(contentResponse?.contentType),
        name: getFileName(contentResponse?.contentDisposition),
        expiryDateUtc: undefined
    };
}

// TODO: #144093: Change CMM file links to use MIME types
function getFileType(mimeType: string) {
    if (mimeType?.startsWith("image"))
        return "Image";
    if (mimeType?.startsWith("application/pdf"))
        return "Pdf";

    return mimeType;
}

function getFileName(contentDispositionValue: string, fallback: string = undefined) {

    if (!contentDispositionValue)
        return fallback;

    try {
        const disposition = contentDisposition.parse(contentDispositionValue || "");
        return disposition?.parameters?.filename ?? fallback;
    }
    catch {
        return fallback;
    }
}