import { UseQueryResult, useQuery } from "@tanstack/react-query";
import axios from "axios";

export type ContentResponse = {
    contentType: string;
    contentDisposition: string;
}

export function useContentHeaders(url?: string): UseQueryResult<ContentResponse, unknown> {
    return useQuery<ContentResponse>(["getFileType", url], async () => {
        const res = await axios.create().head(url);
        return {
            contentType: res.headers['content-type'],
            contentDisposition: res.headers['content-disposition']
        };
    }, {
        enabled: !!url,
    });
}