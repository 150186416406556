

/*eslint unicode-bom: ["error", "always"]*/
//Auto generated scripts from 'Beca.Content.Typescript' using 'Typewriter'




export enum FilterOperation {

    Contains = "Contains",
    Equals = "Equals",
    StartsWith = "Startswith",
    EndsWith = "Endswith",
    IsEmpty = "Isempty",
    IsNotEmpty = "Isnotempty",
    IsAnyOf = "Isanyof"
}
