import { Box, Stack, Typography } from "@mui/material";
import { FileViewLoader } from "components/FileView";
import { ODataFileWithMetadata } from "hooks/useApiV2/useFilesApiV2";

export type FilePreviewProps = {
    file?: ODataFileWithMetadata;
    previewToggleControl?: JSX.Element;
    onClick: () => void;
}

export function FilePreview({ file, onClick, previewToggleControl }: FilePreviewProps) {

    if (file)
        return <FileViewLoader
            fileView="preview"
            fileId={file.id}
            onClick={onClick}
            fallbackMessage
            cursor={"zoom-in"}
            additionalControls={[
                {
                    control: previewToggleControl,
                    position: "start"
                }
            ]}
        />

    return <>
        <Box position="absolute" top="0" left="0" sx={{ padding: 0.5, paddingLeft: 1, paddingTop: "5px" }}>{previewToggleControl}</Box>
        <Stack alignItems="center" justifyContent="center" height="100%">
            <Typography>Select a file to preview.</Typography>
        </Stack>
    </>
}