import { useEffect, useRef, useState } from "react";
import { LazyLibrary } from "./LazyLibrary";
import { useContentHeaders } from "hooks/filestore";
import { Alert } from "@mui/material";

const styles = [
    "https://cdn.pannellum.org/2.5/pannellum.css",
    "https://vjs.zencdn.net/7.19.2/video-js.css"
];

const scripts = [
    "https://cdn.pannellum.org/2.5/pannellum.js",
    "https://vjs.zencdn.net/7.19.2/video.min.js",
    "https://pannellum.org/js/videojs-pannellum-plugin.js"

];

export interface VideoPlayerProps {
    src: string,
    panorama?: boolean
}

export function LazyVideoPlayer(props: VideoPlayerProps) {
    return <LazyLibrary scripts={scripts} styles={styles} >
        <VideoPlayerLoader {...props} />
    </LazyLibrary>
}

/**
 * @description 
 * The Loader is in charge of removing the VideoPlayer from the DOM and create it again when options are changed to prevent conflicts with the previous VideoPlayer
 */
export function VideoPlayerLoader(props: VideoPlayerProps) {

    const [displayPlayer, setDisplayPlayer] = useState(true);

    useEffect(() => {
        setDisplayPlayer(false)
    }, [props.panorama, props.src]);


    useEffect(() => {
        setDisplayPlayer(true)
    }, [displayPlayer]);

    if (displayPlayer)
        return <VideoPlayer {...props} />

    return null;
}

export function VideoPlayer({ src, panorama }: VideoPlayerProps) {

    const contentHeaderRequest = useContentHeaders(src);

    const videoRef = useRef();

    useEffect(() => {

        var contentType = contentHeaderRequest?.data?.contentType;

        if (!contentType)
            return;

        const options = {
            autoplay: false,
            controls: true,
            enableSmoothSeeking: true,
            sources: [{
                src,
                type: contentType
            }],
            plugins: panorama ? { pannellum: {} } : {},
        };

        (window as any).videojs(videoRef.current, options);

    }, [contentHeaderRequest]);

    if (contentHeaderRequest.error || (contentHeaderRequest.isSuccess && !contentHeaderRequest.data?.contentType))
        return <Alert severity="error">An unexpected error occured while loading the video</Alert>

    return (
        <div
            data-vjs-player
            className="video-js vjs-big-play-centered"
            style={{
                position: 'relative',
                width: "100%",
                height: "100%"
            }}
        >
            <video ref={videoRef} crossOrigin="anonymous" />
        </div>
    );
}