import { useState } from "react";
import queue from "queue";
import useApi from "./useApi";
import { NewFileDto } from "types";
const { BlockBlobClient } = require("@azure/storage-blob");

export type UploadFileCallbacks = {
    onStart?: (file: File) => void,
    onProgress?: (file: File, percent: number) => void,
    onComplete?: (file: File) => void,
    onError?: (file: File, error: string) => void,
}

export default function useFileUploader(concurrency = 5) {

    const [uploadQueue,] = useState(queue({ concurrency, autostart: true }));

    const api = useApi();

    return { queueUploadFile };

    async function queueUploadFile(file: File, { onStart = () => { }, onProgress = () => { }, onComplete = () => { }, onError = () => { } }: UploadFileCallbacks): Promise<string> {

        return new Promise(resolve =>
            api.createFile({
                input: { fileName: file.name },
                onSuccess: async (newFileDto: NewFileDto) => {
                    resolve(newFileDto.id)
                    await addToQueue(newFileDto, file, { onStart, onProgress, onComplete, onError });
                },
                onError: (error) => onError(file, error)
            })
        );
    }

    async function addToQueue(fileDto: NewFileDto, file: File, { onStart = () => { }, onProgress = () => { }, onComplete = () => { }, onError = () => { } }: UploadFileCallbacks) {

        if (!file || !fileDto)
            onError(file, "No file provided");

        // Queue the upload worker
        uploadQueue.push(async () => {

            onStart(file);

            const blockBlobClient = new BlockBlobClient(fileDto.sasUri);
            try {
                await blockBlobClient.uploadData(file, {
                    onProgress: (progress: any) => {
                        const percent = progress.loadedBytes * 100 / file.size;
                        onProgress(file, percent);
                    },
                    blobHTTPHeaders: {
                        blobContentType: file.type,
                        blobContentDisposition: `attachment; filename="${file.name}"`,
                    },
                });
            }
            catch (error) {
                // In the case of an upload failure, notify the error to the API and hook consumer, then return early.
                api.uploadFailed({
                    input: { fileId: fileDto.id },
                    onError: (error) => console.error("Unable to inform API of failed upload", error)
                });
                onError(file, error);
                return;
            }

            // Notify the API of a successful upload, then notify the hook consumer.
            api.uploadCompleted({
                input: { fileId: fileDto.id },
                onSuccess: () => onComplete(file),
                onError: (error) => onError(file, error)
            });
        });

        return fileDto.id;
    };
}