import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import Stack from '@mui/material/Stack';

/**
 * DO NOT ALTER: This component is extracted from the legacy @beca/common-react
 * Technical Debt item: #86766
 */


export type SimpleModalProps = {
    children: any,
    open: boolean,
    title: string,
    onClose: () => void
}

export default function SimpleModal({ children, open, title, onClose }: SimpleModalProps) {

    return (
        <Modal open={open} onClose={onClose} sx={{ padding: 6 }} >
            <Box display="flex" justifyContent="center" maxHeight={"100%"} >
                <Box width={700} >
                    <Paper variant="outlined" sx={{overflow:"auto", height: "100%"}} >
                        <Stack p={3} gap={2} >
                            <Box display="flex">
                                <Box flexGrow={1} >
                                    <Typography variant="h5">{title}</Typography>
                                </Box>
                                <Box >
                                    <IconButton onMouseDown={onClose}>
                                        <ClearIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            {children}
                        </Stack>
                    </Paper>
                </Box>
            </Box>
        </Modal>
    );
}

// 100% - mt - mb
