import { useContext } from "react";
import { Gallery } from "components/Gallery";
import { FileViewLoader } from "components/FileView";
import TooltipLink from "components/Tooltip/TooltipLink";
import { ODataFileWithMetadata } from "hooks/useApiV2";
import { FilesContext } from "context";

//TODO replace context data & callback by props #86774
export type FileGalleryProps = {
    tileSize?: number
    selection?: ODataFileWithMetadata[],
    onSelection?: (selection: ODataFileWithMetadata[]) => void,
    onNameClick?: (fileId: ODataFileWithMetadata) => void,
}

export function FileGallery({ tileSize, onNameClick, selection, onSelection }: FileGalleryProps) {

    //TODO replace context data & callback by props #86774
    const filesContext = useContext(FilesContext);

    const { files, page } = filesContext.data;

    return <Gallery
        items={files}
        pageSize={page?.size}
        rowCount={page?.total}
        selectedItems={selection}
        tileSize={tileSize}
        pageIndex={page?.index}
        onSelection={onSelection}
        onPageChange={onPageChange}
        bodyComponent={(file: ODataFileWithMetadata) => <FileViewLoader fileId={file.id} fileView="tile" displayHeader={false} />}
        footerComponent={getFooterComponent}
    />

    function getFooterComponent(file: ODataFileWithMetadata) {
        return <TooltipLink
            color="inherit"
            href="#"
            variant="body2"
            linkId="filenameLink"
            display="block"
            value={file.Name}
            noWrap
            onClick={() => onNameClick && onNameClick(file)} />
    }

    function onPageChange(pageIndex: number) {
        filesContext.setPartialQuery({ pageIndex });
    }
}