import { FileView } from "components/FileView";
import { useParams, useSearchParams } from "react-router-dom";
import { Alert, Box, LinearProgress } from "@mui/material";
import { useContentHeaders, useFilestoreLink, useReadSas } from "hooks/filestore";
import { FileLinks } from "types";

export default function FilestorePreview() {
    const { fileId, workspaceId } = useParams();
    const [searchParams] = useSearchParams();
    const baseUrl = searchParams.get("filestoreUrl");
    const { data: sasUrl, isLoading: isSasLoading, error: sasError } = useReadSas(workspaceId, fileId, baseUrl);
    const { data: contentResponse, isLoading: isContentLoading, error: contentError } = useContentHeaders(sasUrl);
    const link = useFilestoreLink(fileId, sasUrl, contentResponse);

    const isLoading = isSasLoading || isContentLoading;
    const error = (sasError || contentError) ? "Could not load file preview" : undefined;

    return <Box height="100vh" aria-busy={isLoading} aria-describedby="preview-desc">
        <FilestorePreviewContent link={link} error={error} isLoading={isLoading} descId="preview-desc" />
    </Box>;
}

export type FilestorePreviewContentProps = {
    link?: FileLinks,
    isLoading: boolean,
    error?: string,
    descId: string,
}

export function FilestorePreviewContent(props: FilestorePreviewContentProps) {
    const { link, isLoading, error, descId } = props;
    if (error) return <Alert severity="error" id={descId}>Could not load file preview</Alert>;

    if (isLoading) return <LinearProgress id={descId} />;

    return <FileView links={link} allowZoomPanPinch={true} />;
}