import { GridCallbackDetails, GridColDef, GridFilterModel, GridRowParams, GridSelectionModel, GridSortModel, MuiEvent } from "@mui/x-data-grid";
import { DataGridPro, GridColumnVisibilityModel, GridPinnedColumns, GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro"
import { memo, ReactNode, useEffect, useRef, useState, } from 'react';
import { makeStyles } from 'tss-react/mui';
import { last, uniqBy } from "lodash";
import { useSelection, useKeyEvent } from "hooks";
import { Box, Paper } from "@mui/material";
import { Resizable } from "re-resizable";
import TooltipIconButton from "components/Tooltip/TooltipIconButton";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const useStyles = makeStyles()(() => ({
    cell: {
        outline: "none !important"
    }
}));

export type TableProps<TItem extends { id: string }> = {
    columns?: (GridColDef & { show?: boolean })[],
    items?: TItem[],
    pageSize?: number,
    loading?: boolean,
    rowCount?: number,
    selectedItems?: TItem[],
    sortModel?: GridSortModel,
    previewComponent?: (item: TItem, control: ReactNode) => JSX.Element,
    onSelection?: (items: TItem[]) => void,
    onPageChange?: (pageIndex: number) => void
    onSortModelChange?: (sortModel: GridSortModel) => void
    onFilterChange?: (filterModel: GridFilterModel) => void,
    onColumnVisibilityModelChange?: (e: GridColumnVisibilityModel) => void,
}

export const Table = memo(FileTableView_);

function FileTableView_<TItem extends { id: string }>(props: TableProps<TItem>) {

    const { classes } = useStyles();
    const [showPreview, setShowPreview] = useState(true);

    const { items = [], columns = [], selectedItems = [], onSelection, pageSize, previewComponent } = props;

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
    const [pinnedColumns, setPinnedColumns] = useState<GridPinnedColumns>({ left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "name", "Name"], right: ["id"] });

    const pageIndex = useRef(0);

    useEffect(getColumnVisibilityModel, [columns]);

    const { getNext, getPrevious } = useSelection<TItem>({ items, selectedItems });

    useKeyEvent({
        keyEvents: [
            {
                key: "ArrowUp",
                onKeydown: () => selectUsing(getPrevious)
            },
            {
                key: "ArrowDown",
                onKeydown: () => selectUsing(getNext)
            }
        ]
    });

    return (
        <Box display="flex" height="100%" width="100%">
            <DataGridPro
                {...props}
                sx={{ borderRadius: 0, boxSizing: "content-box" }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={onColumnVisibilityModelChange}
                onPageChange={onPageChange}
                rows={items}
                density="compact"
                columns={columns}
                pinnedColumns={pinnedColumns}
                onPinnedColumnsChange={setPinnedColumns}
                selectionModel={selectedItems.map(x => x.id)}
                getCellClassName={() => classes.cell}
                rowsPerPageOptions={[pageSize]}
                onRowClick={onRowClick}
                onSelectionModelChange={onSelectionModelChange}
                onFilterModelChange={props.onFilterChange}
                paginationMode="server"
                sortingMode="server"
                filterMode="server"
                checkboxSelection
                pagination
                getRowHeight={() => 'auto'}
            />
            {(previewComponent && !showPreview) &&
                <Paper variant="outlined" sx={{ width: "auto", height: "100%", marginLeft: "-1px" }} square>
                    <Box alignItems="flex-start">
                        <TooltipIconButton Icon={ChevronLeftIcon} buttonId="showPreviewToggle" onClick={() => { setShowPreview(!showPreview); }} sx={{ padding: 0.5 }} />
                    </Box>
                </Paper>
            }
            {previewComponent && showPreview &&
                <Resizable
                    defaultSize={{ width: "50%", height: "100%" }}
                    minWidth="200px"
                    enable={{ top: false, right: false, bottom: false, left: true, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
                >
                    <Paper variant="outlined" sx={{ width: "100%", height: "100%", marginLeft: "-1px" }} square>
                        <Box display="flex" width="100%" height="100%" alignItems="center" justifyContent="center">
                            {previewComponent(last(selectedItems), <TooltipIconButton Icon={ChevronRightIcon} buttonId="hidePreviewToggle" onClick={() => { setShowPreview(!showPreview); }} sx={{ padding: 0.5 }} />)}
                        </Box>
                    </Paper>
                </Resizable>
            }
        </Box >
    );
    function onPageChange(index: number) {
        pageIndex.current = index;
        props.onPageChange && props.onPageChange(index);
    }

    function onRowClick(params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) {
        event.defaultMuiPrevented = true;
        const item = items.find(x => x.id === params.id);
        onSelection && onSelection([item]);
    }

    function onSelectionModelChange(selectionModel: GridSelectionModel, details: GridCallbackDetails) {

        (document.activeElement as any).blur();
        if (!onSelection) return;

        const selectionOfOtherPages = selectedItems?.filter(selection => !items.find(x => x.id === selection.id));

        const newSelectionInCurrentPage = selectionModel.map(rowId => items.find(item => item.id == rowId.toString()))

        const newSelection = uniqBy([...selectionOfOtherPages, ...newSelectionInCurrentPage].filter(x => !!x), x => x.id);

        onSelection(newSelection);
    }

    function onColumnVisibilityModelChange(model: GridColumnVisibilityModel) {
        if (Object.keys(model).length === 0) {
            model = Object.assign({}, ...columns.map(x => ({ [x.field]: true })));
        }
        props.onColumnVisibilityModelChange(model);
        setColumnVisibilityModel(model);
    }

    function getColumnVisibilityModel() {
        const model = Object.assign({}, ...columns.map(x => ({ [x.field]: x.show })));
        setColumnVisibilityModel(model);
    }

    function selectUsing(getAction: () => TItem) {
        const newItem = getAction();
        if (newItem)
            onSelection([newItem]);
    }
}

