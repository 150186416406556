import { EventType } from "enums/EventType";
import { ReactNode, useEffect, useState } from "react";
import { beyonDarkTheme, beyonLightTheme } from "./Themes";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { Stack, ThemeProvider } from "@mui/material";
import { localStorageUtility } from "utils/storage";

type ThemeMode = 'dark' | 'light'
export default function ThemeUpdater({ children }: { children: ReactNode }) {
    const [themeMode, setThemeMode] = useState<ThemeMode>();
    const theme = beyonTheme(themeMode);
    const { switcher } = useThemeSwitcher();

    const hasParent = hasParentWindow();

    useEffect(() => {
        const storedThemeMode = getThemeModeFromStorage();
        storedThemeMode && changeThemeMode(storedThemeMode);

        if (!hasParent && !storedThemeMode)
            return fallbackToDefault();

        window.addEventListener("message", onMessage);
        window.parent.postMessage({ eventType: EventType.GET_THEME_MODE, payload: {} }, "*");
        //Default to light theme if no message returned
        setTimeout(() => fallbackToDefault(), 1000);
        return () => window.removeEventListener("message", onMessage);
    }, []);


    function onMessage(e: any) {
        const type: EventType = e?.data?.eventType;
        const themeMode = e?.data?.payload?.themeMode;
        switch (type) {
            case (EventType.SET_THEME_MODE): {
                if (!themeMode)
                    return;
                changeThemeMode(themeMode);
                localStorageUtility.setItem('dt-cm-themeMode', themeMode);
                return;
            }
        }
    }

    function beyonTheme(themeMode: ThemeMode) {
        switch (themeMode) {
            case 'dark':
                return beyonDarkTheme;
            case 'light':
                return beyonLightTheme;
            default:
                return beyonLightTheme;
        }
    }

    function changeThemeMode(themeMode: ThemeMode) {
        setThemeMode(themeMode);
        switcher({ theme: themeMode });
    }

    function getThemeModeFromStorage(): ThemeMode | undefined {
        const themeMode = localStorageUtility.getItem('dt-cm-themeMode');
        switch (themeMode) {
            case "dark":
            case "light":
                return themeMode;
        }
    }

    function fallbackToDefault() {
        console.warn("Could not find theme, falling back to light mode theme");
        setThemeMode(x => x ?? 'light');
        switcher({ theme: themeMode ?? 'light' });
    }

    function hasParentWindow() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return false;
        }
    }

    if (!themeMode)
        return; //Wait until theme loaded
    return (
        <ThemeProvider theme={theme}>
            {hasParent ? children : <RootPaper>{children}</RootPaper>}
        </ThemeProvider>
    );
}

export function RootPaper({ children }: { children: ReactNode }) {
    return (
        <Stack minHeight="100vh" bgcolor={(theme) => theme.palette.background.paper}>
            {children}
        </Stack>
    );
}