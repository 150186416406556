import { Box, Grid, Stack, Typography } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { FileStatusDisplay } from "components/FileStatusDisplay";
import TooltipLink from "components/Tooltip/TooltipLink";
import { getMetadataFormatedValue } from "components/MetadataTable/MetadataValueField";
import { uniqBy } from "lodash";
import { useMemo } from "react";
import { FileStatus, Column } from "types";
import { ODataFileWithMetadata } from "hooks/useApiV2/useFilesApiV2";



export type useGridColumnsProps = {
    onNameClick: (name: ODataFileWithMetadata) => void;
    columns: Column[],
    hiddenColumns?: Column[],
};

export function useGridColumns({ onNameClick, columns = [], hiddenColumns = [] }: useGridColumnsProps) {

    const gridColumns = useMemo(getColumns, [columns, hiddenColumns]);

    return { gridColumns };

    function getColumns() {

        var gridColumns: (GridColDef & { show?: boolean })[] = [...columns, ...hiddenColumns]
            .map((column) => ({
                field: column.key,
                headerName: column.name,
                width: 200,
                editable: false,
                show: columns.some(x => x.key === column.key),
                renderCell: renderCell(column.key)
            }));

        return uniqBy(gridColumns, x => x.field);

        function renderCell(columnKey: string) {
            return (params: GridCellParams<string, ODataFileWithMetadata>) => {
                switch (columnKey) {
                    case "Name":
                        return params.row.Status === FileStatus.Completed
                            ? <TooltipLink linkId="filenameLink" value={params.value} color="inherit" href="#" variant="body1" onClick={() => onNameClick && onNameClick(params.row)} />
                            : <Typography color="textSecondary">{params.value}</Typography>
                    case "Status":
                        return <FileStatusDisplay status={params.value as FileStatus} error={params.row.ProcessingError} />
                    case "TenantPath":  
                    case "Path":  
                    case "ProcessingError":  
                        return getMetadataFormatedValue(columnKey, params.value)
                    default:
                        return RenderMetadataField(columnKey, params.row)
                }
            }

            function RenderMetadataField(columnKey: string, file: ODataFileWithMetadata) {
                const metadataValues = [...(file.Metadata[columnKey] || []), ...(file.Metadata[columnKey.toLowerCase()] || [])] as string[];  
                const formattedMeta = metadataValues?.map(value => getMetadataFormatedValue(columnKey, value));

                return <Box sx={
                    {
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        overflow: 'hidden',
                        flexWrap: 'wrap',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}>
                    {formattedMeta?.map((value, index) => (
                        <Box
                            key={index}
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                ':not(:last-child)::after': {
                                    content: { sm: '", "' },
                                    marginRight: 0.5,
                                },
                            }}
                        >
                            {value}
                        </Box>
                    ))}
                </Box>
            }
        }
    }
}


