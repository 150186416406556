import { EventType } from "enums/EventType";
import { useIframeCommunication } from "hooks";
import { Identity } from "hooks/useIdentity";

export function useHostIdentity() {

    const { sendEvent } = useIframeCommunication();

    return { getIdentity };

    function getIdentity() {

        return new Promise<Identity>((resolve, reject) => {

            window.addEventListener('message', onMessage);

            sendEvent({
                eventType: EventType.GET_IDENTITY_TOKEN,
                onError: (error) => {
                    clearTimeout(timeoutId);
                    window.removeEventListener("message", onMessage);
                    reject(error);
                }
            });

            const timeoutId = setTimeout(() => {
                window.removeEventListener("message", onMessage);
                reject("No identity token received from the host");
            }, 1000);

            function onMessage(e: any) {
                const type: EventType = e?.data?.eventType;
                const payload = e?.data?.payload;

                if (type === EventType.SET_IDENTITY_TOKEN) {


                    clearTimeout(timeoutId);
                    window.removeEventListener("message", onMessage);

                    if (payload?.tokenV2 && payload?.decodedV2) {
                        resolve(payload);
                    }
                    else {
                        reject("Invalid token recevied from host");
                    }

                }
            }
        });
    }
}
