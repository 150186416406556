import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { AxiosClientContext } from "context";
import { useContext } from "react";

export type SasResponse = {
    sasUri: string;
}

export function useReadSas(workspaceId: string, fileId: string, baseUrl?: string): UseQueryResult<string, unknown> {
    const { filestoreClient } = useContext(AxiosClientContext);
    return useQuery<string>(["getFileSasUrl", fileId, workspaceId, baseUrl], async () => {
        const res = await filestoreClient?.get<SasResponse>(`/workspace/${workspaceId}/file/${fileId}/sas`, {
            baseURL: baseUrl ?? filestoreClient.defaults.baseURL
        });
        return res.data.sasUri;
    }, {
        enabled: !!filestoreClient,
        refetchOnWindowFocus: false,
    });
}
