import { FilePreview } from "./FilePreview";
import { FileGallery } from "./FileGallery"
import { FileTable } from "./FileTable"
import { File } from "types";
import { ODataFileWithMetadata } from "hooks/useApiV2/useFilesApiV2";

//TODO move FileGallery & FileTable context data & callback as props here #86774 
export type FileExplorerProps = {
    displayView: "table" | "gallery",
    preview?: boolean,
    tileSize?: number,
    selection?: ODataFileWithMetadata[],
    onSelection?: (selection: ODataFileWithMetadata[]) => void,
    onNameClick?: (file: ODataFileWithMetadata) => void,
    onPreviewClick?: (file: ODataFileWithMetadata) => void,
}

export function FileExplorer(props: FileExplorerProps) {

    const { preview, onPreviewClick } = props;

    if (props.displayView === "gallery")
        return <FileGallery {...props} />

    if (preview)
        return <FileTable
            {...props}
            previewComponent={
                (file: ODataFileWithMetadata, control: JSX.Element) => <FilePreview
                    file={file}
                    onClick={() => onPreviewClick && onPreviewClick(file)}
                    previewToggleControl={control}
                />
            }

        />

    return <FileTable {...props} />
}
