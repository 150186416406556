import { useQuery } from "@tanstack/react-query";
import buildQuery, { Filter } from "odata-query";
import { FilterOperation, Column, FileFilterDto as ConfigFilter, DocumentsConfig, FileType } from "types";
import { useContext } from "react";
import { AxiosClientContext } from "context";
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro";

const fileProperties = [
    "TenantPath",
    "Status",
    "Name",
    "Path",
    "ProcessingError"] as const;
type FilePropertyType = typeof fileProperties[number];

export function useFilesApiV2(tenant: string, query: FileQuery, documentConfig: DocumentsConfig) {
    const { client } = useContext(AxiosClientContext);
    const { data, isError, isLoading, refetch } = useQuery({
        queryKey: ["listFiles", tenant, query],
        queryFn: async () => {
            const queryString = getODataQuery(query, documentConfig);
            const result = await client.get<FilesV2Response>(`/v2/${tenant}/files${queryString}`);
            return {
                files: result.data?.value?.map(flattenFile),
                count: result.data["@odata.count"]
            };
        },
        enabled: !!client
    });

    return {
        files: data?.files,
        count: data?.count,
        isLoading,
        isError,
        refetch
    }
}

function flattenFile(odataFile: ODataFile): ODataFileWithMetadata {
    const { Metadata: unflattenedMetadata, ...rest } = odataFile;
    return {
        ...rest,
        id: odataFile.Id,
        Metadata: unflattenedMetadata.reduce((acc, meta) => {
            if (!acc[meta.Key]) acc[meta.Key] = [];
            acc[meta.Key].push(meta.Value);
            return acc;
        }, {} as { [key: string]: string[] })
    };
}

export type FilesV2Response = {
    value: ODataFile[]
    "@odata.count": number
}

export type ODataFile = {
    [key in FilePropertyType]: string;
} & {
    Metadata: OdataMetadata[];
    Id: string;
};

export type MetadataDictionary = { [key: string]: string[] }

export type ODataFileWithMetadata = {
    [key in FilePropertyType]: string;
} & {
    Metadata: MetadataDictionary;
    id: string;
};

export type OdataMetadata = {
    Id: string,
    FileId: string,
    Key: string,
    Value: string,
    System: boolean,
    Immutable: boolean
}
export type FileQuery = {
    sortModel?: GridSortModel,
    filterModel?: GridFilterModel,
    columns?: Column[],
    recycled?: boolean,
    pageIndex: number,
    pageSize: number
}
function isFileProperty(property: string): property is FilePropertyType {
    return (fileProperties as readonly string[]).includes(property);
}

export function getODataQuery(query: FileQuery, documentConfig: DocumentsConfig) {
    const { sortModel, filterModel, pageIndex, pageSize, recycled } = query;
    const { filters: configFilters, fileType } = documentConfig;

    const fileSelect = [...fileProperties, "Id"];

    const deletedFilter: Filter = recycled ? { DeletedOn: { ne: null } } : { DeletedOn: null };

    const orderBy = sortModel?.map(s => `${s.field} ${s.sort ?? "asc"}`);

    const queryString = buildQuery({
        select: fileSelect.join(","),
        filter: [
            filterModelToODataFilter(filterModel),
            deletedFilter,
            ...configFiltersToODataFilter(configFilters),
            fileTypeToOdataFilter(fileType)
        ],
        expand: ["metadata"],
        orderBy,
        skip: pageIndex * pageSize,
        top: pageSize,
        count: true
    });
    return queryString;
}

function filterModelToODataFilter(filter: GridFilterModel): Filter {
    if (!filter?.items)
        return {};

    if (filter.items.length === 1) {
        const item = filter.items[0];
        return isFileProperty(item.columnField)
            ? propertyToOdataFilter(item.columnField, item.operatorValue, item.value)
            : metadataFilterToODataFilters(item.columnField, item.operatorValue, item.value)
    }
    return {
        [filter.linkOperator ?? "and"]: filter.items.map(item =>
            isFileProperty(item.columnField)
                ? propertyToOdataFilter(item.columnField, item.operatorValue, item.value)
                : metadataFilterToODataFilters(item.columnField, item.operatorValue, item.value)
        )
    }
}

function configFiltersToODataFilter(filters: ConfigFilter[]): Filter[] {
    if (!filters)
        return [];

    return filters.map(filter => {
        const { property, operator, value: values } = filter;
        if (values.length === 1)
            return isFileProperty(property)
                ? propertyToOdataFilter(property, operator, values[0])
                : metadataFilterToODataFilters(property, operator, values[0]);

        return isFileProperty(property)
            ? { or: values.map(v => propertyToOdataFilter(property, operator, v)) }
            : { or: values.map(v => metadataFilterToODataFilters(property, operator, v)) }
    })
}

function fileTypeToOdataFilter(fileType: FileType): Filter | undefined {
    if (!fileType)
        return undefined;
    return metadataFilterToODataFilters("Type", "Equals",  fileType);
}

function metadataFilterToODataFilters(columnField: string, operatorValue: string, value: string | string[]): Filter {
    return {
        Metadata: {
            any: [
                {
                    key: columnField,
                    ...propertyToOdataFilter("value", operatorValue, value)
                }
            ]
        }
    }
}

function propertyToOdataFilter(property: string, operator: string, value: string | string[]) {
    switch (operator.toLowerCase()) {
        case FilterOperation.Contains.toLowerCase(): return { [property]: { contains: value } };
        case FilterOperation.Equals.toLowerCase(): return { [property]: value };
        case FilterOperation.StartsWith.toLowerCase(): return { [property]: { startswith: value } };
        case FilterOperation.EndsWith.toLowerCase(): return { [property]: { endswith: value } };
        case FilterOperation.IsEmpty.toLowerCase(): return { [property]: "" };
        case FilterOperation.IsNotEmpty.toLowerCase(): return { [property]: { ne: "" } };
        case FilterOperation.IsAnyOf.toLowerCase(): { return { [property]: { in: value as string[] } } }
    }
}