import { GridFilterModel, GridSortModel } from "@mui/x-data-grid";
import { memo, ReactNode, useContext, useMemo } from 'react';
import { ConfigContext, MetadataKeysContext } from "context";
import { isEqual } from "lodash";
import { useGridColumns } from "./useGridColumns";
import { Table } from "components/Table";
import { GridColumnVisibilityModel } from "@mui/x-data-grid-pro";
import { FilesContext } from "context";
import { ODataFileWithMetadata } from "hooks/useApiV2";

export type FileTableProps = {
    selection?: ODataFileWithMetadata[],
    onNameClick?: (file: ODataFileWithMetadata) => void,
    onSelection?: (selection: ODataFileWithMetadata[]) => void,
    previewComponent?: (item: any, control: ReactNode) => JSX.Element,
}

export const FileTable = memo(FileTable_);

function FileTable_(props: FileTableProps) {

    const { onNameClick, selection } = props;

    //TODO replace context data & callback by props #86774
    const documentConfig = useContext(ConfigContext).config.documents;
    const filesContext = useContext(FilesContext);
    const metadataKeysContext = useContext(MetadataKeysContext);

    const hiddenColumns = useMemo(() => {
        if (Array.isArray(metadataKeysContext.metadataKeys)) {
            return metadataKeysContext.metadataKeys.map(x => ({ key: x, name: x }));
        }
        return []; // Return an empty array or handle the non-array case appropriately
    }, [metadataKeysContext.metadataKeys]);

    const { gridColumns } = useGridColumns({
        onNameClick,
        columns: documentConfig.columns,
        hiddenColumns
    });

    return (
        <Table
            {...props}
            columns={gridColumns}
            items={filesContext.data?.files || []}
            pageSize={filesContext.data?.page.size || 0}
            loading={filesContext.data?.loading ?? false}
            rowCount={filesContext.data?.page.total || 0}
            selectedItems={selection}
            sortModel={filesContext.query.sortModel}
            onColumnVisibilityModelChange={onColumnVisibilityModelChange}
            onPageChange={onPageChange}
            onSortModelChange={onSortModelChange}
            onFilterChange={onFilterChange}
        />
    )

    function onPageChange(pageIndex: number) {
        filesContext.setPartialQuery({ pageIndex });
    }

    function onSortModelChange(sortModel: GridSortModel) {
        if (isEqual(sortModel, filesContext.query.sortModel))
            return;

        filesContext.setPartialQuery({ sortModel });
    }

    function onFilterChange(filterModel: GridFilterModel) {
        filesContext.setPartialQuery({ filterModel });
    }

    function onColumnVisibilityModelChange(model: GridColumnVisibilityModel) {
        filesContext.setPartialQuery({
            columns: Object.entries(model).filter(([col, show]) => show).map(([col, show]) => ({ key: col, name: col }))
        });
    }
}