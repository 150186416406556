import { ReactNode, useCallback, useContext, useMemo, useState } from "react"
import { Box, Theme, Stack } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import { ConfigContext, SelectionContext, MetadataKeysContext } from "context";

import { FileExplorerHeader, FileExplorerView } from "components/FileExplorerHeader";
import { FileExplorer } from "components/FileExplorer";
import SimpleModal from "components/@beca-common-react-legacy/SimpleModal";
import FileDelete from "components/FileDelete";
import { useUploaderUrl, useDownloadFile, useCreatedFiles, useApi } from "hooks";
import FileShare from "components/FileShare";
import { Fullscreen } from "components/Fullscreen";
import { SimpleCarousel } from "components/SimpleCarousel";
import { FileViewLoader } from "components/FileView";
import { FileEdit } from "components/FileEdit";
import { last } from "lodash";
import Feedback, { FeedbackProps } from "components/@beca-common-react-legacy/Feedback";
import { ODataFileWithMetadata } from "hooks/useApiV2/useFilesApiV2";
import { FilesContext } from "context";

const explorerHeaderHeight = 40;
const useStyles = makeStyles()(({ spacing }: Theme) => ({
    fileExplorer: {
        maxHeight: `calc(100% - ${explorerHeaderHeight}px - ${spacing(3)})`,
    },
}));

export default function Documents() {

    const { classes } = useStyles();
    const api = useApi();

    const config = useContext(ConfigContext).config;
    const { allowPreview, allowDelete, allowUpload } = config.documents;
    const { selection, setSelection, selectNext, selectPrevious } = useContext(SelectionContext);
    const { data, query, setPartialQuery, refresh } = useContext(FilesContext);
    const { refetch: refetchMetadataKeys } = useContext(MetadataKeysContext);
    const { downloadFiles } = useDownloadFile();

    //todo cleanup these states
    const [displayView, setDisplayView] = useState<FileExplorerView>("table");
    const [feedback, setFeedback] = useState<FeedbackProps>();
    const [displayIconSize, setDisplayIconSize] = useState<number>(150);
    const [showFullscreen, setFullscreen] = useState(false);
    const { url: uploadUrl, error } = useUploaderUrl();
    const [modal, setModal] = useState<{ body: ReactNode, title: string, onSuccess?: () => void }>();

    const lastSelection = useMemo(() => last(selection), [selection]);
    const onUpload = useCallback(() => openWindow(uploadUrl), [uploadUrl]);

    useCreatedFiles({
        onNewFiles: () => {
            refresh();
            refetchMetadataKeys();
        }
    });

    return (
        <>
            <Feedback {...feedback} />
            <SimpleModal open={!!modal} title={modal?.title} onClose={() => setModal(null)}>
                {modal?.body}
            </SimpleModal>
            <Fullscreen show={!!showFullscreen} onClose={() => setFullscreen(false)}>
                <SimpleCarousel
                    items={data?.files}
                    item={lastSelection}
                    onNext={selectNext}
                    onPrevious={selectPrevious}
                    itemComponent={(item) => <FileViewLoader
                        fileId={item.id}
                        fileView="original"
                        onClose={() => setFullscreen(false)}
                        allowZoomPanPinch
                        fallbackMessage />
                    }
                />
            </Fullscreen>
            <Stack height="100vh">
                <Box p={1} pb={0} height={explorerHeaderHeight}>
                    <FileExplorerHeader
                        view={displayView}
                        onViewChange={setDisplayView}
                        iconSize={displayIconSize}
                        onIconSizeChange={setDisplayIconSize}
                        selection={selection}
                        recyleBin={query.recycled}
                        upload={allowUpload}
                        deletion={allowDelete}
                        onDelete={onDelete}
                        onRestore={onRestore}
                        onUpload={onUpload}
                        onEdit={onEdit}
                        onShare={onShare}
                        onDownload={onDownload}
                        onRecycleBinChange={onRecycleBinChange}
                    />
                </Box>
                <Box height={`100%`} p={1} display="flex" className={classes.fileExplorer}>
                    <FileExplorer
                        preview={allowPreview}
                        displayView={displayView}
                        tileSize={displayIconSize}
                        selection={selection}
                        onSelection={setSelection}
                        onNameClick={onFileNameClick}
                        onPreviewClick={() => setFullscreen(true)} />
                </Box>
            </Stack>
        </>
    );

    function onRecycleBinChange(recyleBin: boolean) {
        setPartialQuery({ recycled: recyleBin });
    }

    function onFileNameClick(file: ODataFileWithMetadata) {
        setSelection([file]);
        setFullscreen(true);
    }

    function onEdit() {

        setModal({
            title: "Edit files",
            body: <FileEdit fileIds={selection.map(x => x.id)} onSuccess={onSuccess} onCancel={() => setModal(null)} />,
            onSuccess
        })

        function onSuccess() {
            setModal(null);
            refresh();
            refetchMetadataKeys();
        }
    }

    function onShare() {
        setModal({
            title: "Share files",
            body: <FileShare fileIds={selection.map(x => x.id)} />,
        });
    }

    function openWindow(url: string) {
        if (!url) {
            setFeedback({ message: "Error: Context has not loaded properly", severity: "error", neverHide: true });
            console.error('Error: Context has not loaded properly')
            return;
        }
        //TODO error needs to be logged to application insights
        const myRef = window.open(url, '_blank', 'left=20,top=20,width=800,height=800,toolbar=1,resizable=0');
        myRef?.focus();
    }

    function onDownload() {
        downloadFiles({ fileIds: selection.map(x => x.id) });
    }

    function onDelete() {
        setModal({
            title: "Delete files",
            body: <FileDelete onSuccess={onSuccess} onCancel={() => setModal(null)} />,
            onSuccess
        });

        function onSuccess() {
            setModal(null);
            setSelection([]);
            refresh();
        }
    }

    function onRestore() {
        api.restoreFiles({
            input: { fileIds: selection.map(x => x.id) },
            onSuccess: refresh
        });
    }
}

