import { Fragment } from "react";
import { Box, CircularProgress, TextField } from "@mui/material";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import { AutocompleteOptionItem } from "./AutocompleteOptionItem"

export type AutocompleteInputProps = AutocompleteRenderInputParams & {
    loading: boolean,
    isRequired?: boolean,
    option: AutocompleteOptionItem,
    newOptionText: string
}

export default function AutocompleteInput(props: AutocompleteInputProps) {

    const { loading, option, newOptionText = 'option', isRequired } = props;
    const helper = getHelperText(option, newOptionText, isRequired);

    return (
        <TextField
            {...props}
            FormHelperTextProps={helper.props}
            helperText={helper.text}
            InputProps={{
                ...props.InputProps,
                ...helper.props,
                endAdornment: (
                    <Fragment>
                        {props.InputProps.endAdornment}
                        {loading &&
                            <Box
                                marginRight="5px"
                                bgcolor="white"
                                zIndex="1"
                                display="flex"
                            >
                                <CircularProgress color="inherit" size={20} />
                            </Box>}
                    </Fragment>
                ),
            }}
        />
    )
}

function getHelperText(option: AutocompleteOptionItem, newOptionText: string, isRequired: boolean) {

    if (isRequired && (option?.value === null || option?.value === undefined)) {
        return {
            props: { error: true },
            text: `This ${newOptionText} cannot be empty`
        }
    }

    if (!option?.isNewEntry)
        return {};

    return {
        text: `"${option.value}" will be added as a new ${newOptionText}. If ${newOptionText} is used to generate the hierarchy there will be a short delay before it appears in the navigation panel.`
    }
}