import { SyntheticEvent, useMemo, useState } from "react";
import { FilterOptionsState } from "@mui/material";
import MuiAutocomplete, { createFilterOptions, AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import Input from "./AutocompleteInput"
import NoOption from "./AutocompleteNoOption"
import { AutocompleteOptionItem } from "./AutocompleteOptionItem"

const filter = createFilterOptions();

type AutocompleteProps = {
    disabled?: boolean,
    isRequired?: boolean,
    value: string,
    newOptionText: string,
    options: string[],
    loadOptions: () => void,
    onChange: (value: string) => void,
}

export default function Autocomplete({ disabled, value, newOptionText, options, isRequired, loadOptions, onChange }: AutocompleteProps) {

    const [open, setOpen] = useState<boolean>(false);
    const [defaultValue] = useState(value ? { defaultValue: { value: value } } : {});

    const optionsReady = Array.isArray(options);
    const opened = open && optionsReady;
    const loading = open && optionsReady === false;
    const { values, currentValue } = useMemo(generateValues, [options, value]);

    return (
        <MuiAutocomplete
            disabled={disabled}
            onOpen={() => {
                setOpen(true);
                if (!open)
                    loadOptions();
            }}
            onClose={() => setOpen(false)}
            open={opened}
            {...defaultValue}
            isOptionEqualToValue={(v1: AutocompleteOptionItem, v2: AutocompleteOptionItem) => v1.value === v2.value}
            onChange={onAutocompleteChange}
            filterOptions={filterOptions}
            size="small"
            freeSolo={opened}
            forcePopupIcon={opened || 'auto'}
            options={values}
            loading={loading}
            renderOption={(props, option: AutocompleteOptionItem) => (option.isNoOptions ? <NoOption /> : <li {...props}>{option.display || option.value}</li>)}
            getOptionLabel={(option: AutocompleteOptionItem) => value || option?.value || ""}
            renderInput={(props: AutocompleteRenderInputParams) => (<Input {...props} loading={loading} newOptionText={newOptionText} option={currentValue} isRequired={isRequired} />)}
        />
    );

    function onAutocompleteChange(event: SyntheticEvent<Element, Event>, newValue: AutocompleteOptionItem) {
        onChange(newValue?.value);
    }

    function generateValues() {
        const results: AutocompleteOptionItem[] = (options || [])
            .filter((x: string) => x)
            .map<AutocompleteOptionItem>((x: string) => ({ value: x, display: x, isNoOptions: false }));

        // Value is not found in options and a value has been selected
        var valueIndex = results.findIndex((o) => o.value === value);
        if (valueIndex === -1 && value) {
            valueIndex = results.length;
            results.push({
                value: value,
                display: `Add "${value}"`,
                isNewEntry: optionsReady
            });
        }

        // Inform the user there are no values
        if (results.length === 0) {
            const noOptions: AutocompleteOptionItem = { value: null, isNoOptions: true };
            return {
                values: [noOptions],
                currentValue: noOptions
            };
        }

        return {
            values: results,
            currentValue: results[valueIndex]
        };
    }

    function filterOptions(options: AutocompleteOptionItem[], state: FilterOptionsState<AutocompleteOptionItem>): AutocompleteOptionItem[] {
        if (state.inputValue === '')
            return options;

        options = options.filter((x) => !x.isNoOptions);
        const filtered = filter(options, state) as AutocompleteOptionItem[];

        if (filtered.length > 0)
            return filtered;

        // When there are no values, offer an option to add the value
        return [
            {
                value: state.inputValue,
                display: `Add "${state.inputValue}"`,
                isNewEntry: true
            } as AutocompleteOptionItem
        ];
    }
}





