import { ChangeEvent } from "react";
import { TextField } from "@mui/material";
import NonEditableField from "components/NonEditableField"
import { Metadata, MetadataInputType } from "types"
import MetadataValueAutocomplete from "./MetadataValueAutocomplete";
import prettyBytes from "pretty-bytes"
import { formatFileType } from "utils/functions";

export interface MetadataValueFieldProps {
    allowEdit: boolean,
    disabled?: boolean,
    metadata: Metadata,
    onChange: (value: string) => void,
}

export default function MetadataValueField({ metadata, disabled, allowEdit, onChange }: MetadataValueFieldProps) {
    const isEditable = !metadata.system && (allowEdit || metadata.isNew);
    if (isEditable)
        return <EditableValue metadata={metadata} onChange={onChange} disabled={disabled} />;

    const tooltip = metadata.system ? "System metadata are not editable" : null
    return <NonEditableField value={getMetadataFormatedValue(metadata.key, metadata.value)} tooltip={tooltip} />;
}


function EditableValue({ disabled, metadata, onChange }: { metadata: Metadata, disabled?: boolean, onChange: (values?: string) => void }) {

    if (metadata.inputType === MetadataInputType.Autocomplete)
        return (
            <MetadataValueAutocomplete disabled={disabled} metadata={metadata} onChange={onAutocompleteChange} />
        );

    return (
        <TextField
            disabled={disabled}
            fullWidth
            variant="outlined"
            size="small"
            value={metadata.value || ""}
            onChange={onTextFieldChange} />
    );

    function onAutocompleteChange(option: string) {
        onChange(option);
    }

    function onTextFieldChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        onChange(event?.target?.value);
    }
}

export function getMetadataFormatedValue(key: string, value: string) {
    switch (key.toLowerCase()) {
        case "size": {
            const sizeInt = parseInt(value);
            return sizeInt ? prettyBytes(sizeInt) : value;
        }
        case "width":
        case "height":
            return value ? `${value} px` : value;
        case "type":
            return formatFileType(value)
        case "extension":
            return value?.slice(1).toLocaleUpperCase();
        default:
            return value;
    }
}
