import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { Dispatch, SetStateAction, useContext, useMemo } from "react";
import { ConfigContext, MetadataKeysContext } from "context";
import { Metadata } from "types"
import ActionCell from "./ActionCell"
import EmptyMessage from "./EmptyMessage"
import MetadataKeyField from "./MetadataKeyField"
import MetadataValueField from "./MetadataValueField"
import NewRow from "./NewRow"

export type MetadataTableProps = {
    disabled?: boolean,
    metadatas: Metadata[],
    onChange: (metadatas: Metadata[]) => void,
    allowNew?: boolean,
    allowDelete?: boolean,
    allowEditKeys?: boolean,
    allowEditValues?: boolean,
    displaySystemMetadata?: boolean,
    emptyMessage?: string,
}

export function MetadataTable({
    metadatas: rawMetadatas = [],
    disabled,
    onChange,
    allowEditKeys = true,
    allowEditValues = true,
    allowNew = true,
    allowDelete = true,
    displaySystemMetadata = false,
    emptyMessage = "No metadata",
}: MetadataTableProps) {

    const metadataKeysContext = useContext(MetadataKeysContext);
    const metadataKeys = metadataKeysContext.loading ? null : metadataKeysContext.metadataKeys
    const { inputFields } = useContext(ConfigContext).config.upload;
    const metadatas = rawMetadatas.map(x => ({ ...x, inputType: inputFields.find(y => y.key === x.key)?.inputType }));
    return (
        <>
            <EmptyMessage hidden={metadatas.length > 0} message={emptyMessage} />
            <TableContainer>
                <Table size="small">
                    <NewRow disabled={disabled} hidden={!allowNew} onAdd={onAddMetadata} />
                    <TableBody>
                        {metadatas.map((metadata, i) => (
                            shouldDisplay(metadata) &&
                            <TableRow key={i}>
                                <TableCell width="40%" sx={{ verticalAlign: "top" }}>
                                    <MetadataKeyField allowEdit={allowEditKeys} disabled={disabled} metadata={metadata} values={metadataKeys} onChange={(key) => updateMetadatasKey(i, key)} />
                                </TableCell>
                                <TableCell width="60%" align="right" sx={{ verticalAlign: "top" }}>
                                    <MetadataValueField allowEdit={allowEditValues} metadata={metadata} disabled={disabled} onChange={(value) => updateMetadatasValue(i, value)} />
                                </TableCell>
                                <ActionCell disabled={disabled} isEditable={isEditable(metadata)} index={i} onAction={onDeleteMetadata} />
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );

    function shouldDisplay(metadata: Metadata): boolean {
        return displaySystemMetadata || !metadata.system;
    }

    function updateMetadatasKey(index: number, key?: string) {
        onChange(([
            ...metadatas.slice(0, index),
            { ...metadatas[index], key },
            ...metadatas.slice(index + 1),
        ]));
    }

    function updateMetadatasValue(index: number, value?: string) {
        onChange(([
            ...metadatas.slice(0, index),
            { ...metadatas[index], value },
            ...metadatas.slice(index + 1),
        ]));
    }

    function onDeleteMetadata(index: number) {
        onChange(metadatas.filter((_, i) => i !== index));
    }

    function onAddMetadata() {
        onChange([...metadatas, { key: "Tag", isNew: true }]);
    }

    function isEditable(metadata: Metadata): boolean {
        if (metadata.system)
            return false;

        if (metadata.isRequired)
            return false;

        if (metadata.isNew)
            return true;

        return allowDelete;
    }
}

