import { TenantContext, ConfigContext } from "context";
import { useFilesApiV2, ODataFileWithMetadata, FileQuery } from "hooks/useApiV2";
import { createContext, useContext, useState } from "react";
import { Pagination, DocumentsConfig } from "types";

export type FilesData = {
    files: ODataFileWithMetadata[],
    page: Pagination,
    loading: boolean,
}

type FilesContextState = {
    data: FilesData;
    query: FileQuery;
    setQuery: (query: FileQuery) => void;
    setPartialQuery: (query: Partial<FileQuery>) => void;
    refresh: () => void;
}

const defaultState: FilesContextState = {
    data: null,
    query: {
        columns: [{ key: "Name", name: "Name" }, { key: "Status", name: "Status" }],
        recycled: false,
        pageIndex: 0,
        pageSize: 25
    },
    setQuery: () => { },
    setPartialQuery: () => { },
    refresh: () => { }
};

export const FilesContext = createContext<FilesContextState>(defaultState);

export function FilesProvider({ children }: { children: JSX.Element }) {
    const { tenant } = useContext(TenantContext);
    const { documents: documentConfig } = useContext(ConfigContext).config;
    const [query, setQuery] = useState<FileQuery>(getDefaultQuery(documentConfig));

    const { files, count, isLoading, refetch } = useFilesApiV2(tenant.path, query, documentConfig);

    const data: FilesData = {
        files,
        page: {
            total: count,
            size: query?.pageSize,
            index: query?.pageIndex
        },
        loading: isLoading
    }
    return (
        <FilesContext.Provider value={{
            data,
            query,
            setQuery,
            setPartialQuery: (partial) => setQuery(prev => ({ ...prev, ...partial })),
            refresh: refetch
        }}>
            {children}
        </FilesContext.Provider>
    )
};

function getDefaultQuery(documentConfig: DocumentsConfig): FileQuery {
    const { columns: columnConfigs, sorts: sortConfigs } = documentConfig;
    const query = {
        ...defaultState.query
    }
    if (columnConfigs)
        query.columns = columnConfigs;
    if (sortConfigs)
        query.sortModel = sortConfigs.map(s => ({ field: s.property, sort: s.ascending ? "asc" : "desc" }))

    return query;
}