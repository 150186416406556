import axios, { AxiosInstance } from 'axios';
import { ReactNode, createContext } from 'react';
import { useIdentity } from "hooks";
import { useQuery } from "@tanstack/react-query";
import { Identity } from 'hooks/useIdentity';


export type AxiosClientState = {
    client?: AxiosInstance;
    filestoreClient?: AxiosInstance;
    isLoading: boolean;
}

const defaultState: AxiosClientState = {
    client: undefined,
    isLoading: true
};

export const AxiosClientContext = createContext<AxiosClientState>(defaultState);

export function AxiosClientProvider({ children }: { children: ReactNode }) {
    const { getIdentity } = useIdentity();

    const { data, isLoading } = useQuery(["identity"], async () => {
        const identity = await getIdentity();
        return {
            client: getClient(identity),
            filestoreClient: getClient(identity, `${(window as any).REACT_APP_FILESTORE_API_ENDPOINT}`)
        }
    }, {});
    const { client, filestoreClient } = data ?? {};

    function getClient(identity: Identity, baseUrl?: string) {
        return axios.create({
            baseURL: baseUrl ?? `${(window as any).REACT_APP_API_ENDPOINT}`,
            headers: { Authorization: `Bearer ${identity.tokenV2}` }
        });
    }

    return (
        <AxiosClientContext.Provider value={{ client, filestoreClient, isLoading }}>
            {children}
        </AxiosClientContext.Provider>
    )
};