import { Box, Divider, Stack, Typography } from "@mui/material";
import { FileLinks, FileThumbnails } from "types";
import { ImageViewer } from "components/ImageViewer";
import { FileFallback } from "./FileFallback";
import { useEffect, useMemo, useState } from "react";
import TooltipIconButton from "components/Tooltip/TooltipIconButton";
import { Clear } from "@mui/icons-material";
import { PanoramaViewer } from "components/PanoramaViewer";
import { FileViewSwitcher } from "./FileViewSwitcher";
import { ControlledBar, Controls as ControlledBarControls } from "./ControlledBar";
import { PDFViewer } from "@progress/kendo-react-pdf-viewer";
import { ViewInFullscreenButton } from "./ViewInFullscreenButton";
import { LazyVideoPlayer } from "components/VideoPlayer";

export type FileViewProps = {
    links?: FileLinks;
    fileView?: FileThumbnails;
    allowZoomPanPinch?: boolean;
    fallbackMessage?: boolean;
    displayHeader?: boolean,
    cursor?: string;
    additionalControls?: ControlledBarControls;
    onClick?: () => void;
    onClose?: () => void;
};

export type FileViewType = "icon" | "image" | "image-panorama" | "pdf-viewer" | "video" | "video-panorama";

function getDefaultImageView(type: string, fileView: FileThumbnails) {

    if (type === "Image")
        return "image"

    if (type === "Pdf")
        return fileView === "tile" ? "icon" : "pdf-viewer"

    if (type?.toLocaleLowerCase()?.startsWith("video"))
        return "video";

    return "icon";
}


export function FileView(props: FileViewProps) {

    const { cursor, fallbackMessage, links, fileView, displayHeader = true, onClose, additionalControls, onClick } = props;
    const [fileViewType, setFileViewType] = useState<FileViewType>(getDefaultImageView(links.type, fileView));

    useEffect(() => {
        setFileViewType(getDefaultImageView(links.type, fileView));
    }, [links.type, fileView])

    const url = useMemo(() => {
        if (!fileView || !links.thumbnailLinks)
            return links.link;

        return links.thumbnailLinks[fileView];
    }, [fileView, links.thumbnailLinks])

    const controls: ControlledBarControls = [
        ...(additionalControls ? additionalControls : []),
        { control: <Typography variant="body1" className="fileView-header-fileName">{links.name}</Typography>, position: "start" as const },
        { control: <FileViewSwitcher fileViewType={fileViewType} onChange={setFileViewType} />, position: "end" as const },
        { control: <ViewInFullscreenButton show={fileView === "preview"} onClick={onClick} />, position: "end" as const },
        ...(onClose ? [{ control: <TooltipIconButton Icon={Clear} buttonId="exitFullscreen" onClick={onClose} className="fileView-header-closeButton" sx={{ padding: "4px" }} />, position: "end" as const }] : [])
    ];

    return (
        <Stack width="100%" height="100%" spacing={1}>
            {displayHeader &&
                <>
                    <ControlledBar className="fileView-header" height="39px" controls={controls} />
                    <Divider sx={{ marginTop: "0 !important" }} />
                </>
            }
            <Box flexGrow={1} height="50%" id="fileView-render">
                {renderFileView(fileViewType)}
            </Box>
        </Stack >
    );

    function onImageLoadError() {
        setFileViewType("icon");
    }

    function renderFileView(fileViewType: string): React.ReactNode {
        switch (fileViewType) {
            case "image":
                return <ImageViewer {...props} url={url} name={links.name} fullsizeUrl={links.link} cursor={cursor} onError={onImageLoadError} />
            case "image-panorama":
                return <PanoramaViewer url={links.link} />
            case "pdf-viewer":
                return <PDFViewer url={links.link} style={{ height: "100%" }} />
            case "video":
            case "video-panorama":
                return <LazyVideoPlayer src={links.link} panorama={fileViewType.includes("panorama")} />
            default:
                return <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <FileFallback name={links.name} message={fallbackMessage && `No preview available for "${links.name}"`} />
                </Box>;
        }
    }
}

