import { createContext, useContext, useMemo } from "react";
import { BusContext } from "context";
import { useSelection } from "hooks";
import { ODataFileWithMetadata } from "hooks/useApiV2/useFilesApiV2";
import { FilesContext } from "./filesContext";

type SelectionState = {
    selection: ODataFileWithMetadata[],
    setSelection: (selection: ODataFileWithMetadata[]) => void,
    selectNext: () => void,
    selectPrevious: () => void,

}
const defaultSelectionState: SelectionState = {
    selection: [],
    setSelection: () => { },
    selectNext: () => { },
    selectPrevious: () => { },
}
export const SelectionContext = createContext<SelectionState>(defaultSelectionState);

type SelectionProviderProps = {
    children: JSX.Element,
}

export function SelectionProvider({ children }: SelectionProviderProps) {

    const { select, doSelect } = useContext(BusContext);
    const { data } = useContext(FilesContext);

    const selection = useMemo(() => (data?.files || []).filter(f => (select?.tags ?? []).includes(f.id)), [select, data?.files]);

    const items = useMemo(() => (data?.files || []), [data?.files]);

    const { getNext, getPrevious } = useSelection<ODataFileWithMetadata>({ items, selectedItems: selection });

    return (
        <SelectionContext.Provider value={{
            selection,
            setSelection,
            selectPrevious: () => selectUsing(getPrevious),
            selectNext: () => selectUsing(getNext),
        }}>
            {children}
        </SelectionContext.Provider>
    );

    function setSelection(newSelection: ODataFileWithMetadata[]) {
        doSelect({ tags: newSelection.map(x => x.id) });
    }

    function selectUsing(getAction: () => ODataFileWithMetadata) {
        const newItem = getAction();
        if (newItem)
            setSelection([newItem]);
    }
}

export default SelectionProvider;
